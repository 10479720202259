@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Noto+Sans&display=swap');
@import './generic.scss';

.App {
  margin: auto;
  overflow: hidden;
  height: 100%;
  width: 100%;

  > .page {
    width: 100%;
    height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
  
    &.oh-snap {
      scroll-snap-type: y mandatory;

      .snap-start {
        scroll-snap-align: start;
      }
      .snap-end {
        scroll-snap-align: end;
      }
    }

    > .page-body {
      width: 100%;
      max-width: 1100px;
      min-height: 100%;
      padding: 0 20px;
      margin: 0 auto;
    }
  }
}


h2.align-center {
  text-align: center;
}

.bg-pict {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50vw;
  background-color: black;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100vw;
  margin: 0;
}

button, .link-btn {
  border-radius: 6px;
  border: 1px solid rgb(76, 76, 76);  background-color: #121212a3;
  backdrop-filter: blur(5px);
  font-family: 'M PLUS Rounded 1c', sans-serif;
  border: none;
  border-radius: 6px;
  padding: 9px 13px;

  transition: all .3s ease;
  &.secondary {
    background-color: rgb(133, 133, 133);
  }
  &:hover {
    background-color: rgb(55, 55, 55);
    cursor: pointer;
  }
  &:active {
    background-color: rgb(72, 72, 72);
  }
  &:disabled {
    opacity: .5;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.songlist-footer {
  height: 100px;
  button {
    border: none;
    margin: 20px 0;
    margin-left: 8px;
    padding: 4px 20px;
    font-style: italic;
    opacity: 0.7;
    &:hover {
      background-color: rgb(62, 62, 62);
    }
  }
}

.link-btn {
  text-decoration: none;
  display: block;

  @media screen and (max-width: 600px) {
    display: block;
    text-align: center;
  }
}

.sticky-link-btn {
  position: sticky;
  top: 17px;
  margin-top: 14px;
  margin-left: 0;
  float: right;
  z-index: 1;
}

.pane {
  background-attachment: fixed;
  backdrop-filter: blur(5px);
  background-color: rgba(24, 24, 24, 0.567);
  padding: 10px 15px;
  border-radius: 6px;
}

.sticky-section {
  position: sticky;
  margin: 0 -5px;
  top: 0;
  left: 14px;
  right: 14px;
  margin-bottom: 30px;
  z-index: 2;
  &.anon {
    top: 14px;
  }
}

.page.Songlist .sticky-section {
  position: fixed;
  top: unset;
  left: unset;
  right: unset;
  width: min(100vw - 30px, 1100px - 30px);
}

.spacer {
  height: 100px;
}

.sticky-section-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 102px;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 0;
  z-index: 1;
  transition: opacity 0.3s ease;
  opacity: 0;
  backdrop-filter: blur(10px);
  &.shown {
    opacity: 1;
  }
}

.back-to-queue-btn {
  position: sticky;
  float: right;
  // right: 14px;
  top: 107px;
  display: block;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-orientation: sideways;
  text-align: center;
  margin: 14px  0 0;
  padding: 6px 6px;
}

.modal-dialog-thing {
  transition: transform .5s ease, opacity .3s ease;
  position: fixed;
  z-index: 1;

  &.pane, .pane {
    background-color: rgba(255, 255, 255, 0.674);
  }

  &.invisible {
    opacity: 0;
    transform: translateY(100px);
    pointer-events: none;
  }
  p, h2, h3 {
    color: black;
  }
  p {
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 4px;
  }
  h2 {
    margin: 0;
    font-size: 25px;
    line-height: 120%;
  }
  h3 {
    font-style: italic;
    margin: 5px 0;
    font-size: 15px;
    letter-spacing: 0.5px;
  }
  button {
    margin: 12px 0 0;
  }
  img {
    width: 200px;
    max-width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
    transition: all .5s ease;
    &.invisible {
      opacity: 0;
      transform: translateY(100px);
    }
    &.selected-cover {
      max-width: 40vw;
    }
  }

  &.selected-song {
    bottom: 14px;
    max-width: 80vw;
    width: min(400px, 100vw - 90px);
    pointer-events: none;
    .pane {
      position: relative;
    }
    &:not(.invisible) > .pane {
      pointer-events: all;
    }
    .close-btn {
      position: absolute;
      padding: 1px 6px;
      right: 10px;
      bottom: 10px;
      &::before {
        content: '×';
      }
    }
    .year-label {
      font-weight: 100;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0;
      margin-inline-start: 9px;
      font-size: 12px;
    }
    .corner-labels {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      gap: 5px;

      .lang-label, .added-label {
        font-weight: 100;
        background-color: #e5e5e5;
        color: black;
        opacity: 0.6;
        font-size: 10px;
        padding: 1px 4px;
        border-radius: 4px;
        text-transform: uppercase;
      }
      .added-label {
        font-style: italic;
      }
    }
    > * {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.733);
    }
  }
  &.scroll-to-top, &.select-random {
    z-index: 1;
    button {
      width: 40px;
      height: 40px;
      background-color: rgba(121, 121, 121, 0.456);
      &:hover { background-color: rgba(143, 143, 143, 0.456); }
      &:active { background-color: rgba(173, 173, 173, 0.456); }
    }
  }
  &.scroll-to-top {
    bottom: 63px;
    transition: translate 0.3s ease, opacity 0.3s ease;
    &.hidden {
      opacity: 0;
      translate: 0 15px;
    }
  }
  &.select-random {
    bottom: 14px;
  }
  &.scroll-to-top, &.select-random {
    right: calc(14px + max(100vw - 1070px, 0px) / 2 + 5px);
  }
}

.alert-modal.pane {
  top: 50%;
  left: 50vw;
  max-width: 300px;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding-top: 15px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.363);
  > button {margin: 15px auto 0;}
  h2 {
    font-size: 1.3rem;
    // color: white;
    // text-shadow: 0px 3px 0px black;
  }
  p {
    margin-top: 10px;
    font-size: 0.7rem;
    a {
      color: white;
      @keyframes pulse-bw {
        to {color: rgb(206, 206, 206)}
      }
      text-decoration: none;
      letter-spacing: .1rem;
      animation: pulse-bw .2s infinite alternate;
      font-style: italic;
      &:hover {
        color: white;
        animation: none;
      }
    }
  }

  &.invisible {
    transform: translate(-50%, calc(-50% + 100px));
  }
  
  &.error-modal {
    background-color: #ffababa8;
  }

  .flex-row {
    justify-content: center;
  }

  .menu-btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 15px;
    button {
      backdrop-filter: none;
      background-color: rgb(63, 63, 63);
      margin: 0;
      border-radius: 7px;
      padding: 8px 15px;
      box-shadow: black 2px 2px 0px;
      &:hover {
        background-color: rgb(113, 113, 113);
      }
    }
  }

  &.type-selector {
    width: 60vw;
    max-height: 70vh;
    overflow-y: scroll;
    .menu-btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

.backdrop-glass-pane {
  position: fixed;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.282);
  backdrop-filter: blur(2px);
  transition: opacity .5s ease;
  z-index: 2;
  top: 0;
  &.invisible {
    pointer-events: none;
    opacity: 0;
  }
}

.input-block {
  margin-top: 14px;
  border-radius: 6px;
  width: 100%;

  label {
    font-size: 10px;
    margin-bottom: 7px;
    display: block;
  }

  .input-wrapper {
    position: relative;
    .clear-btn {
      border: none;
      padding: 4.5px 9px;
      margin: 0;
      position: absolute;
      right: 8px;
      transform: translateY(-50%);
      top: 50%;
    }

    input {
      width: 100%;
      padding: 8px 16px;
      display: block;
    }
  }
}

.searchbox {
  flex-grow: 1;
}

.name-widget {
  max-width: 300px;
  transition: max-width 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 6px;

  .expanded-content {
    min-width: 100px;
    flex: 1;
  }
  .collapsed-content {
    writing-mode: vertical-lr;
    text-orientation: sideways;
    text-align: center;
    margin-left: 10px;
  }

  @media screen and (max-width: 700px) {
    max-width: 200px;
  }
  &.collapsed {
    max-width: 35px;
  }
}

.input-block-flekz {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 7px;
}

.category-filter {
  padding-right: 5px;
  border-radius: 4px;
  white-space: nowrap;
  margin-right: 3px;
  margin-top: 6px;
  label, em {
    color: black !important;
    font-family: 'M PLUS Rounded 1c', sans-serif;
  }
}

.disablable {
  transition: opacity 0.5s;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
    user-select: none;
  }
}

em {
  font-weight: 100;
}

.filter-section {
  transition: width 0.3s ease;
  width: 100%;
  &.collapsed {
    width: 92px;
    transition-delay: 0.3s;
  }
  &:not(.collapsed) {
    .ReactCollapse--collapse {
      transition-delay: 0.2s;
    }
  }

  h3 {
    margin: 0;
    margin-left: -7px;
    .link-btn {
      backdrop-filter: none;
      font-size: 16px;
      padding: 5px 8px;
      background-color: rgba(0, 0, 0, 0);
      &:hover { background-color: rgba(255, 255, 255, 0.148); }
      &:active { background-color: rgba(255, 255, 255, 0.282); }
      margin: 0;
    }
  }
  .category-filters {
    padding-top: 9px;
    em {
      opacity: .7;
    }
  }
}

.show-unincluded {
  margin-top: 6px;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 6px;
  background-color: black;
}

.Songlist {
  h1 {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.Queue {
  padding-bottom: 20px;
  .song-item {
    display: block;
    padding: 6px 12px;
    transition-property: background-color, margin-bottom;
    margin: 0 -21px;

    @media (min-width: 1113px) {
      border-radius: 10px;
    }
    
    > p, > p > * {
      transition: color .3s ease;
    }
    &.first-item {
      .song-name {
        border: solid #00000026 1px;
      }
      .song-name.selected {
        background-color: rgba(255, 255, 255, 0.386);
        backdrop-filter: brightness(3) contrast(2) blur(5px);
      }
      background-color: rgb(255 255 255 / 42%);
      backdrop-filter: invert(1) brightness(0.9) sepia(1) contrast(1.2) saturate(1.2);
      margin-bottom: 10px;
      > p, > p > * {
        color: black;
      }
    }
    &.second-item {
      backdrop-filter: blur(5px);
      background-color: rgb(255 255 255 / 11%);
      border: solid rgb(255 255 255 / 42%) 1px;
    }
  }

  .sticky-link-btn, .input-block-flekz > .input-block {
    margin-right: -5px;
  }
}

h2.songs-title {
  margin-bottom: 0;
  border-top: 0;
  padding-bottom: 5px;
  border-left: 0;
  border-right: 0;
  border-bottom: solid rgba(255, 255, 255, 0) 1px;
  border-style: dashed;
  transition: border-color 0.3s;
  
  &.with-border {
    border-color: rgba(255, 255, 255, 0.292);
  }
  span {
    vertical-align: middle;
    border: solid white 1px;
    color: white;
    padding: 1px 5px;
    border-radius: 6px;
    font-weight: 100;
    font-size: 10px;
  }
}

.notice {
  margin-top: 16px;
  background-color: rgb(24, 24, 24);
  padding: 5px 10px;
  border-radius: 6px;

  > * {
    margin: 0;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: 100;
  }
  > button {
    margin-top: 7px;
  }
}

.song-item {
  list-style: none;
  padding: 3px 0;
  padding-right: 7px;

  > div {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: row;
  }

  --song-item-bg: rgba(10, 10, 10, 0.8);

  &.clickable, .song-name.clickable {
    cursor: pointer;
    .song-name, &.song-name {
      transition: .2s ease;
      transition-property: background-color, color;
    }

    &:hover {
      --song-item-bg: rgb(43, 43, 43);
    }
    &.selected {
      --song-item-bg: white;
      &, * {
        color: black;
      }
      &:hover {
        --song-item-bg: rgb(222, 222, 222);
      }
    }
  }

  .song-name {
    background-color: var(--song-item-bg);
    border-radius: 6px;
    padding: 3px 9px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    clip-path: inset(0 0 0 0);
    position: relative;

    @keyframes scrll {
      0% { transform: translateX(0); left: 9px; }
      40% { transform: translateX(0); left: 9px; animation-timing-function: ease; }
      80% { transform: translateX(-100%); left: calc(100% - 18px + 9px); animation-timing-function: ease-in; }
      100% { transform: translateX(0); left: 9px; }
    }

    > span:first-child {
      display: inline-block;
    }

    > span.anim {
      display: none;
    }

    &.scrolling {
      > span:first-child {
        opacity: 0;
      }
      > span.anim {
        display: inline-block;
        position: absolute;
        left: 9px;
        top: 2px;
        animation: scrll forwards infinite ease;
      }
    }
  }

  span {
    font-family: 'M PLUS Rounded 1c', sans-serif;
  }
}

.category-chip {
  background-color: white;
  padding: 1px 5px;
  border-radius: 6px;
  color: black;
  white-space: nowrap;
  font-size: 12px;
}

h1, h2, h3 {
  > button {
    border: none;
    margin: 0;
    margin-left: 8px;
    padding: 4px 8px;
    vertical-align: middle;
  }
}

.ReactCollapse--collapse {
  transition-property: height, opacity;
  transition: 300ms ease;
  &[aria-hidden="true"] {
    opacity: 0;
  }
}

.tiled-view {
  > div > div > div {
    position: relative;
    margin: auto;
    text-align: center;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
}

  .song-item {
    padding: 10px 10px;
    background-color: var(--song-item-bg);
    border-radius: 6px;
    margin: 0 14px 14px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

    .img-wrapper {
      background-image: url(NoCoverDeluxe_dark.jpg);
      background-size: cover;
      background-position: center;
      overflow: hidden;
      border-radius: 6px;
      position: relative;
      margin-bottom: 6px;
      flex: 1;
    }

    img {
      transition: opacity 0.2s ease;
      width: 100%;
      overflow: hidden;
      position: relative;
      object-fit: cover;

      &.invisible {
        opacity: 0;
      }
    }

    .song-name {
      background: none;
    }
  }
}

.votes-count {
  background-color: white;
  padding: 2px 7px;
  margin-inline-start: 10px;
  border-radius: 40px;
  color: black;
  white-space: nowrap;
  font-size: 14px;
}

.vote-btn {
  border-radius: 40px;
  padding: 4px 15px;
  &:hover {
    color: white;
  }
  &:disabled {
    opacity: 1;
    background-color: black;
    color: white;
    &.locked {
      opacity: .4;
    }
  }
}

.q-item-label {
  font-size: 13px;
  margin: 0 0 4px 5px;
}

.q-item-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@keyframes fade-in-appear {
  from { opacity: 0 }
}

.fade-in-appear {
  animation: fade-in-appear 1s linear;
}

.admin-buttons {
  margin-top: 14px;
}

.admin.page {
  h1 {
    margin-top: 50px;
    margin-bottom: 70px;
  }
  input {
    min-width: 250px;
  }
}
