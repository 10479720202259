:root {
  height: 100%;
}

body {
  background-color: black;
  position: relative;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

* {
  font-family: 'Noto Sans', sans-serif;
  color: rgb(238, 238, 238);
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;

  &-thumb {
    background-color: rgba(255, 255, 255, 0.144);
    &:hover {
      background-color: rgba(255, 255, 255, 0.226);
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.377);
    }
  }
  &-corner,
  &-button,
  &-track {
    display: none;
  }
}

ul {
  padding-left: 0;
}

button {
  border: none;
}

select {
  appearance: none;
  border: none;
  transition: background-color .4s;
  border-radius: 6px;
  background-color: black;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: rgb(39, 39, 39);
  }
}

input {
  background-color: rgba(12, 12, 12, 0.7);
  border: none;
  border-radius: 6px;
  padding: 6px 10px;
  font-size: 14px;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  text-overflow: clip;
}

.flex-row {
  display: flex;
  gap: 8px;
  &.wrap {
    flex-wrap: wrap;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

